/* eslint-disable react/prop-types */
//ignorei18n_start
import { Button,Menu, MenuItem, ButtonGroup, TextField } from "@mui/material";
import React, { useState, useContext } from "react";
import Collapse from '@mui/material/Collapse';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import SearchIcon from '@mui/icons-material/Search';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import WarningIcon from '@mui/icons-material/Warning';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DangerousIcon from '@mui/icons-material/Dangerous';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
export default function Header({
    handleSearch,
    handleDateRange,
    handleTicketDuration,
    handleTicketState
}) {

    return (
        <div style={{
            height: "30px",
            backgroundColor: "rgb(248 250 252)",
            // boxShadow: "1px 1px 20px 1px rgba(71, 85, 105, 0.25)",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0px 10px",
            boxSizing: "border-box"
        }}>
            <Lefty handleSearch={handleSearch} handleDateRange={handleDateRange}/>
            <Middle />
            <Righty handleTicketDuration={handleTicketDuration} handleTicketState={handleTicketState}/>
            {/* <div style={{minWidth: "500px"}}></div> */}
        </div>
    );

}

function Lefty({
    handleSearch,
    handleDateRange
}) {
    const [open, setOpen] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [dateOpen, setDateOpen] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const getPastMonths = () => {
        const months = [];
        for (let i = 1; i <= 3; i++) {
            months.push(dayjs().subtract(i, 'month').format('MMMM'));
        }
        return months;
    };

    const pastMonths = getPastMonths();

    const handleSubmit = () => {
        const formattedStartDate = startDate ? dayjs(startDate).format('YYYY-MM-DD') : null;
        const formattedEndDate = endDate ? dayjs(endDate).format('YYYY-MM-DD') : null;
        handleDateRange(formattedStartDate, formattedEndDate);
        setDateOpen(false);
      };
    
      const handleReset = () => {
        setDateOpen(false);
        setStartDate(null);
        setEndDate(null);
        handleDateRange("Reset", "Reset");
      };

      const handleDateRangeChange = (months) => {
        const newStartDate = dayjs().subtract(months, 'month').format('YYYY-MM-DD');
        const newEndDate = dayjs().format('YYYY-MM-DD');
        setStartDate(newStartDate);
        setEndDate(newEndDate);
    };

    return (
        <div style={{
            minWidth: "500px",
            maxWidth: "500px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
        }}>
            <div style={{ position: "relative" }}>
              <Button style={{ minWidth: "0px" }} onClick={() => setDateOpen(s => !s)}>
                <DateRangeOutlinedIcon style={{ color: "#A40606"}}/>
              </Button>
              {dateOpen && (
                <div style={{ position: 'absolute', top: '100%', left: 0, zIndex: 1, background: 'white', border: '1px solid #ccc', padding: '10px', maxHeight: "600px",
                overflowY: "auto"  }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box sx={{ margin: "0px 270px", gap: "2px" }} display="flex" flexDirection="row">
                        <Button onClick={() => handleDateRangeChange(1)} autoFocus>{pastMonths[0]}</Button>
                        <Button onClick={() => handleDateRangeChange(2)} autoFocus>{pastMonths[1]}</Button>
                        <Button onClick={() => handleDateRangeChange(3)} autoFocus>{pastMonths[2]}</Button>
                    </Box>
                    <Box display="flex" flexDirection="row">
                        <Typography variant="caption">Start Date</Typography>
                            <DateCalendar value={startDate ? dayjs(startDate) : null} onChange={(newStartDate) => setStartDate(newStartDate)} />
                        <Typography variant="caption" style={{ marginTop: '10px' }}>End Date</Typography>
                        <DateCalendar  value={endDate ? dayjs(endDate) : null} onChange={(newEndDate) => setEndDate(newEndDate)} />
                    </Box>
                    <Box sx={{padding:"0px 250px",gap:"2px"}}>
                        <Button onClick={() => setDateOpen(false)}>Cancel</Button>
                        <Button onClick={handleReset}>Reset</Button>
                        <Button onClick={handleSubmit} autoFocus> Submit</Button>
                    </Box>
                  </LocalizationProvider>
                </div>
              )}
            </div>
            <Button style={{ minWidth: "0px" }}
                onClick={() => setOpen(s => !s)}
            >
                <SearchIcon style={{ color: "#A40606"}}/>
            </Button>
            <Collapse orientation="horizontal" in={open}>
                <TextField
                    variant="standard"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyDown={e => e.code === "Enter" ? handleSearch(searchText) : null}
                    size="small"
                    style={{
                        width: "400px",
                        fontSize: "14px"
                    }}
                />
            </Collapse>
        </div>
    );
}

function Middle() {
    return (
        <div style={{
            color: "#A40606",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "3px",
            fontSize: "14px"
        }}>
            <NewReleasesIcon fontSize="14px"/>
            Support Timeline
        </div>
    );
}

function Righty({handleTicketDuration, handleTicketState }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedFilter, setSelectedFilter] = useState("All");
    const [selectedTimeFilter, setSelectedTimeFilter] = useState("Normal");
    const [timeAnchorEl, setTimeAnchorEl] = useState(null); // State for Time Filter menu
    const theme = useTheme();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleTimeClick = (event) => {
        setTimeAnchorEl(event.currentTarget); // Set the anchor element for the time menu
    };

    const handleSelectClose = () => {
        setAnchorEl(null);
    };

    const handleTimeMenuClose = () => {
        setTimeAnchorEl(null);
    };

    const handleMenuItemClick = (buttonName) => {
        setSelectedFilter(buttonName);
        handleTicketState(buttonName);
        handleSelectClose();
    };

    const handleTimeMenuItemClick = (Selected_Time_Data) => {
        setSelectedTimeFilter(Selected_Time_Data);
        handleTicketDuration(Selected_Time_Data);
        setTimeAnchorEl(null); // Close the time menu after selection
    };

    return (
        <div style={{
            minWidth: "500px",
            maxWidth: "500px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end"
        }}>
             <ButtonGroup sx={{
                backgroundColor: theme.palette.mode === "dark" ? "white" : "#F7F7F7",
                borderColor: theme.palette.mode === "dark" ? "white" : "#A40606"}}>
                {/* First Button */}
                <Button 
                    sx={{ 
                        color: "#A40606", 
                        fontWeight:"bold",
                        fontSize: "12px",
                        padding: "4px 8px",
                        minWidth: "100px"
                    }} 
                    onClick={handleClick} 
                    endIcon={<ArrowDropDownIcon />}
                >
                    {selectedFilter}
                </Button>

                {/* Second Button */}
                <Button 
                    sx={{ 
                        color: "#A40606", 
                        fontWeight: "bold",
                        fontSize: "12px",
                        padding: "4px 8px",
                        minWidth: "100px",
                        display: "flex",
                        alignItems: "center"
                    }} 
                    onClick={handleTimeClick}  // Properly handle the time menu click
                    endIcon={<ArrowDropDownIcon />}
                >
                    {selectedTimeFilter}
                </Button>
            </ButtonGroup>

            {/* First button menu */}
            <Menu
                id="customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'customized-button'
                }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleSelectClose}
            >
                <MenuItem
                    sx={{ 
                        width: "124px", 
                        justifyContent: "flex-start", 
                        fontSize: "12px", 
                        display: 'flex', 
                        alignItems: 'center' 
                    }}
                    onClick={() => handleMenuItemClick("Ongoing")}
                    disableRipple
                >
                    <MoreHorizIcon fontSize="small" sx={{ fontSize: "15px" }} />
                    &nbsp;&nbsp;Ongoing
                </MenuItem>
                <MenuItem
                    sx={{ 
                        width: "124px", 
                        justifyContent: "flex-start", 
                        fontSize: "12px", // Ensure consistent font size with the button
                        display: 'flex', 
                        alignItems: 'center' 
                    }}
                    onClick={() => handleMenuItemClick("Rejected")}
                    disableRipple
                >
                    <DangerousIcon fontSize="small" sx={{ fontSize: "15px" }} />
                    &nbsp;&nbsp;Rejected
                </MenuItem>
                <MenuItem
                    sx={{ 
                        width: "124px", 
                        justifyContent: "flex-start", 
                        fontSize: "12px", 
                        display: 'flex', 
                        alignItems: 'center' 
                    }}
                    onClick={() => handleMenuItemClick("Waiting")}
                    disableRipple
                >
                    <HourglassTopIcon fontSize="small" sx={{ fontSize: "15px" }} />
                    &nbsp;&nbsp;Waiting
                </MenuItem>
                <MenuItem
                    sx={{ 
                        width: "124px", 
                        justifyContent: "flex-start", 
                        fontSize: "12px", 
                        display: 'flex', 
                        alignItems: 'center' 
                    }}
                    onClick={() => handleMenuItemClick("Completed")}
                    disableRipple
                >
                    <HowToRegIcon fontSize="small" sx={{ fontSize: "15px" }} />
                    &nbsp;&nbsp;Completed
                </MenuItem>
                <MenuItem
                    sx={{ 
                        width: "124px", 
                        justifyContent: "flex-start", 
                        fontSize: "12px", 
                        display: 'flex', 
                        alignItems: 'center' 
                    }}
                    onClick={() => handleMenuItemClick("All")}
                    disableRipple
                >
                    <AutoStoriesIcon fontSize="small" sx={{ fontSize: "15px" }} />
                    &nbsp;&nbsp;All
                </MenuItem>
            </Menu>

            {/* Second button menu */}
            <Menu
                id="time-data-menu"
                anchorEl={timeAnchorEl}
                open={Boolean(timeAnchorEl)}
                onClose={handleTimeMenuClose}
            >
                <MenuItem
                    sx={{ 
                        width: "114px", 
                        justifyContent: "flex-start", 
                        fontSize: "12px", // Ensure consistent font size with the button
                        display: 'flex', 
                        alignItems: 'center' 
                    }}
                    onClick={() => handleTimeMenuItemClick("Delayed")}
                    disableRipple
                >
                    <WarningIcon fontSize="small" sx={{ fontSize: "15px" }} />
                    &nbsp;&nbsp;Delayed
                </MenuItem>
                <MenuItem
                    sx={{ 
                        width: "114px", 
                        justifyContent: "flex-start", 
                        fontSize: "12px", // Ensure consistent font size with the button
                        display: 'flex', 
                        alignItems: 'center' 
                    }}
                    onClick={() => handleTimeMenuItemClick("On Time")}
                    disableRipple
                >
                    <AssignmentTurnedInIcon fontSize="small" sx={{ fontSize: "15px" }} />
                    &nbsp;&nbsp;On Time
                </MenuItem>
                <MenuItem
                    sx={{ 
                        width: "114px", 
                        justifyContent: "flex-start", 
                        fontSize: "12px", // Ensure consistent font size with the button
                        display: 'flex', 
                        alignItems: 'center' 
                    }}
                    onClick={() => handleTimeMenuItemClick("Normal")}
                    disableRipple
                >
                    <MoreHorizIcon fontSize="small" sx={{ fontSize: "15px" }} />
                    &nbsp;&nbsp;Normal
                </MenuItem>
            </Menu>
        </div>        
    );
}

//ignorei18n_end