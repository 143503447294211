//ignorei18n_start
import React, { useState, useRef, useLayoutEffect, useEffect, useContext } from 'react';
import Header from "./header/Header";
import TimelineComponent from "./timeline/Timeline";
import { getFromTicketsReport} from "../TickectDataFetcherForTimeLinePage/TickectDataFetcherForTimeLinePage"
import {ticketsReport_page_component_get_query_1} from "../../../../catalystDB/queryGenerator";
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import EmptyData from '../../../../icons/EmptyData.png'
import { UserContext } from '../../../../contexts/UserContext';
// import InfiniteScroll from 'react-infinite-scroll-component'
 
export default function SupportTimelinePage({
    handleSearchTextChange,
    // data
}) {

    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [LazzyLoading, setLazzyLoading] = useState(false);
    const [offsetData, setOffsetData] = useState(1);
    const [incrementData, setIncrementData] = useState(100);
    const [userSerchedText, setUserSearchedText] = useState("No Data");
    const [startDate, setStartDate] = useState("Reset");
    const [endDate, setEndDate] = useState("Reset");
    const [ticketState, setTicketState] = useState("All");
    const [ticketTime, setTicketTime] = useState("Normal");
    const [allDataWithoutAdding,setlAlDataWithoutAdding] = useState();
    const { serverConfiguration ,GlobalServer} = useContext(UserContext);
    const isInitialMount = useRef(true);
    const globalServerData = (GlobalServer === "unknown" || GlobalServer === undefined) ? null : GlobalServer;
    // console.log(serverConfiguration)

    // var offsetData = 1;
    // var incrementData = 100;
    // var handleSearchTextChange = (v) => console.log(v);

    function CircularWithValueLabel({ loading }) {
        const [progress, setProgress] = React.useState(10);
      
        React.useEffect(() => {
          const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
          }, 800);
          return () => {
            clearInterval(timer);
          };
        }, []);
      
        return loading ? (
            <div style={{ position: "fixed", top: "50%", left: "60%", transform: "translate(-50%,-50%)" }}>
                 <CircularProgressWithLabel value={progress} />
            </div>
        ) : null;
      }

      function CircularProgressWithLabel(props) {
        return (
          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography variant="caption" component="div" color="text.secondary">
                {`${Math.round(props.value)}%`}
              </Typography>
            </Box>
          </Box>
        );
      }

      CircularProgressWithLabel.propTypes = {
        /**
         * The value of the progress indicator for the determinate variant.
         * Value between 0 and 100.
         * @default 0
         */
        value: PropTypes.number.isRequired,
      };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                var server_products = [];
                if (globalServerData !== null) {
                    if (GlobalServer in serverConfiguration.current) {
                        const products = JSON.parse(serverConfiguration.current[GlobalServer].IssueProductType);
                        products.forEach(prod => {
                            if (!server_products.includes(prod)) {
                                server_products.push(prod);
                            }
                        });
                    }
                }
                
                var productName = server_products?.join(`','`) || "undefined";
                const localQueryFilter = [startDate, endDate, userSerchedText, productName, ticketState, ticketTime].filter(Boolean);
                const result = await getFromTicketsReport(ticketsReport_page_component_get_query_1(localQueryFilter,"ZVP",productName),offsetData);
                // console.log(result)
                const parsedResult = result.map(o => {
                    try {
                        return JSON.parse(o, dateTimeReviver);
                    } catch (e) {
                        console.log(e);
                        return o;
                    }
                });
                setData(parsedResult);
                setlAlDataWithoutAdding(result)
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        var server_products = [];
        if (GlobalServer in serverConfiguration.current) {
            const products = JSON.parse(serverConfiguration.current[GlobalServer].IssueProductType);
            products.forEach(prod => {
                if (!server_products.includes(prod)) {
                    server_products.push(prod);
                }
            });
        }
        const fetchDataGLobalServer = async () => {
            try {
                setLoading(true);

                var server_products = [];
                if (globalServerData !== null) {
                    if (GlobalServer in serverConfiguration.current) {
                        const products = JSON.parse(serverConfiguration.current[GlobalServer].IssueProductType);
                        products.forEach(prod => {
                            if (!server_products.includes(prod)) {
                                server_products.push(prod);
                            }
                        });
                    }
                }
                
                var productName = server_products?.join(`','`) || "undefined";
                const localQueryFilter = [startDate, endDate, userSerchedText, productName, ticketState, ticketTime].filter(Boolean);
                const result = await getFromTicketsReport(ticketsReport_page_component_get_query_1(localQueryFilter,"ZVP"),offsetData);
                
                const parsedResult = result.map(o => {
                    try {
                        return JSON.parse(o, dateTimeReviver);
                    } catch (e) {
                        console.log(e);
                        return o;
                    }
                });
                setData(parsedResult);
                setlAlDataWithoutAdding(result)
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        if (isInitialMount.current) {
            isInitialMount.current = false;
        }
        else{
            fetchDataGLobalServer();
        }
        
    }, [GlobalServer]);
    

    var handleSearchTextChange = async (v) => {
            try {
                var server_products = [];
                if (v === "" || v === null || v === undefined || v === " ") {
                    v = "No Text";
                }
                if (globalServerData !== null) {
                    if (GlobalServer in serverConfiguration.current) {
                        const products = JSON.parse(serverConfiguration.current[GlobalServer].IssueProductType);
                        products.forEach(prod => {
                            if (!server_products.includes(prod)) {
                                server_products.push(prod);
                            }
                        });
                    }
                }

                var productName = server_products?.join(`','`) || "undefined";
                const localQueryFilter = [startDate, endDate, v, productName, ticketState, ticketTime].filter(Boolean);
                setLoading(true);
                setOffsetData(1)
                setUserSearchedText(v);

                const result = await getFromTicketsReport(ticketsReport_page_component_get_query_1(localQueryFilter,"ZVP"),offsetData);
                const parsedResult = result.map(o => {
                    try {
                        return JSON.parse(o, dateTimeReviver);
                    } catch (e) {
                        console.log(e);
                        return o;
                    }
                });
                setData(parsedResult);
                setlAlDataWithoutAdding(result)
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
    };

    var handleDateRange= async (startDate, endDate) => {
            try {
                setOffsetData(1);
                setLoading(true);
                var server_products = [];
                if (globalServerData !== null) {
                    if (GlobalServer in serverConfiguration.current) {
                        const products = JSON.parse(serverConfiguration.current[GlobalServer].IssueProductType);
                        products.forEach(prod => {
                            if (!server_products.includes(prod)) {
                                server_products.push(prod);
                            }
                        });
                    }
                }
                
                var productName = server_products?.join(`','`) || "undefined";
                const localQueryFilter = [startDate, endDate, userSerchedText, productName, ticketState, ticketTime].filter(Boolean);
                const result = await getFromTicketsReport(ticketsReport_page_component_get_query_1(localQueryFilter,"ZVP",productName),offsetData);

                const parsedResult = result.map(o => {
                    try {
                        return JSON.parse(o, dateTimeReviver);
                    } catch (e) {
                        console.log(e);
                        return o;
                    }
                });
                setData(parsedResult);
                setStartDate(startDate);
                setEndDate(endDate);
                setlAlDataWithoutAdding(result)
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
    
 };

 var handleTicketState= async (Selected_State) => {
    try {
        setOffsetData(1);
        setLoading(true);
        var server_products = [];
        if (globalServerData !== null) {
            if (GlobalServer in serverConfiguration.current) {
                const products = JSON.parse(serverConfiguration.current[GlobalServer].IssueProductType);
                products.forEach(prod => {
                    if (!server_products.includes(prod)) {
                        server_products.push(prod);
                    }
                });
            }
        }
        
        var productName = server_products?.join(`','`) || "undefined";
        const localQueryFilter = [startDate, endDate, userSerchedText, productName, Selected_State, ticketTime].filter(Boolean);
        const result = await getFromTicketsReport(ticketsReport_page_component_get_query_1(localQueryFilter,"ZVP",productName),offsetData);

        const parsedResult = result.map(o => {
            try {
                return JSON.parse(o, dateTimeReviver);
            } catch (e) {
                console.log(e);
                return o;
            }
        });
        setData(parsedResult);
        setTicketState(Selected_State);
        setlAlDataWithoutAdding(result)
    } catch (error) {
        console.error(error);
    } finally {
        setLoading(false);
    }
};

var handleTicketDuration = async (Selected_timeFilter) => {
    try {
        setOffsetData(1);
        setLoading(true);
        var server_products = [];
        if (globalServerData !== null) {
            if (GlobalServer in serverConfiguration.current) {
                const products = JSON.parse(serverConfiguration.current[GlobalServer].IssueProductType);
                products.forEach(prod => {
                    if (!server_products.includes(prod)) {
                        server_products.push(prod);
                    }
                });
            }
        }

        var productName = server_products?.join(`','`) || "undefined";
        const localQueryFilter = [startDate, endDate, userSerchedText, productName, ticketState, Selected_timeFilter].filter(Boolean);
        const result = await getFromTicketsReport(ticketsReport_page_component_get_query_1(localQueryFilter, "ZVP", productName), offsetData);

        const parsedResult = result.map(o => {
            try {
                return JSON.parse(o, dateTimeReviver);
            } catch (e) {
                console.log(e);
                return o;
            }
        });

        // Apply filtering logic based on ticketState and Selected_timeFilter
        let filteredResult = parsedResult;
        
        if (ticketState === "Completed") {
            filteredResult = parsedResult.filter(ticket => {
                const createdTime = new Date(ticket.created_time);
                const completedTime = new Date(ticket.completed_time);
                const timeDifference = completedTime - createdTime;

                const fiveDaysInMilliseconds = 5 * 24 * 60 * 60 * 1000;

                if (Selected_timeFilter === "Delayed") {
                    return timeDifference > fiveDaysInMilliseconds;
                } else if (Selected_timeFilter === "On Time") {
                    return timeDifference <= fiveDaysInMilliseconds;
                }

                return true; // Keep the ticket if no filtering condition matches
            });
        }

        setData(filteredResult);
        setTicketTime(Selected_timeFilter);
        setlAlDataWithoutAdding(result);
    } catch (error) {
        console.error(error);
    } finally {
        setLoading(false);
    }
};



    const fetchData = async () => {
        var offsetDataCount = offsetData + 300;
        try {
            var server_products = [];
            if (globalServerData !== null) {
                if (GlobalServer in serverConfiguration.current) {
                    const products = JSON.parse(serverConfiguration.current[GlobalServer].IssueProductType);
                    products.forEach(prod => {
                        if (!server_products.includes(prod)) {
                            server_products.push(prod);
                        }
                    });
                }
            }
            
            var productName = server_products?.join(`','`) || "undefined";
            const localQueryFilter = [startDate, endDate, userSerchedText, productName, ticketState, ticketTime].filter(Boolean);
            const result = await getFromTicketsReport(ticketsReport_page_component_get_query_1(localQueryFilter,"ZVP",productName),offsetDataCount);
            // const result = await getFromTicketsReport(ticketsReport_page_component_get_query_1(),offsetData);
            // console.log(result)
            const parsedResult = result.map(o => {
                try {
                    return JSON.parse(o, dateTimeReviver);
                } catch (e) {
                    console.log(e);
                    return o;
                }
            });
            setData([...data, ...parsedResult]);
            setlAlDataWithoutAdding(result)
            setOffsetData(offsetDataCount)
        } catch (error) {
            console.error(error);
        } finally {
            setLazzyLoading(false);
        }
    };

    const dateTimeReviver = function (key, value) {
        if (key.includes("date") && typeof value === 'string' && isDate(value)) {
            return new Date(value);
        }
        return value;
    }

    var isDate = function (date) {
        return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
    }

    return (
        <div  className="page-content-2">
            <Header
                handleSearch={handleSearchTextChange}
                handleDateRange={handleDateRange}
                handleTicketDuration = {handleTicketDuration}
                handleTicketState = {handleTicketState}
            />
             <CircularWithValueLabel loading={loading} />
             {!loading && data.length !==0 ? (
                    <TimelineComponent data={data} fetchData={fetchData} LazzyLoading={LazzyLoading} setLazzyLoading={setLazzyLoading} offsetData={offsetData} 
                    incrementData={incrementData} setOffsetData={setOffsetData} setIncrementData ={setIncrementData} allDataWithoutAdding={allDataWithoutAdding}
                    ticketTime={ticketTime}
                    />
            ) : !loading && data.length === 0 ? (
                <div style={{
                    textAlign: "center",
                    marginTop: "40vh",
                    transform: "translateY(-50%)"
                  }}>
                    <img src ={EmptyData} alt="No Data Found Illustration" style={{ width: "200px" }} />
                    <p style={{ fontFamily: "Arial, sans-serif", fontSize: "20px", fontWeight: "bold", color: "#333" }}>
                      Oops! Looks like there's no data here.
                    </p>
                  </div>   
            ) : null}
        </div>
    );
}
//ignorei18n_end