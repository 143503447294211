//ignorei18n_start
import React, { useEffect, useRef,useState } from "react";

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { TimelineOppositeContent } from "@mui/lab";
import {
    timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import { Chip, Divider, Stack, Tooltip } from "@mui/material";

import { styled } from '@mui/material/styles';

import DoneIcon from '@mui/icons-material/Done';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import Ticket from "./ticket/Ticket";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

export default function TimelineComponent({ data,fetchData,LazzyLoading,setLazzyLoading,offsetData,incrementData,setOffsetData,setIncrementData,allDataWithoutAdding,ticketTime}) {

    // var data = 0.78;
    // <SplitSingle data={data} index={0}/>

    const ColorlibConnector = styled(TimelineConnector)(({ theme }) => ({
        [`&.MuiTimelineConnector-root`]: {
            backgroundImage:
                // 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
                'linear-gradient( 95deg, #A40606 0%, #D98324 100%)'
        }
    }));

    const ColorlibDot = styled(TimelineDot)(({ theme }) => ({
        [`&.MuiTimelineDot-filled`]: {
            backgroundImage:
                // 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
                'linear-gradient( 95deg, #A40606 0%, #D98324 100%)',
            backgroundClip: 'padding-box'
        }
    }));

    sortData(data);

    const ref = useRef();
    var ind = 0;
    var closestDiff = Infinity;
    const today = new Date();
    data.map((d, i) => {
        if(d?.created_time || d?.completed_time) {
            const date = d.completed_time ? new Date(d.completed_time) : new Date(d.created_time);
            const diff = Math.abs(today.getTime() - new Date(date).getTime());
            if(diff <= closestDiff) {
                closestDiff = diff;
                ind = i;
            }
        }
    })

    useEffect(() => {
        if (ref.current) {
        ref.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
        }
    }, []);
    
    const handleScroll = async (event) => {
        const container = event.target;
        // console.log(data.length);
        if (container.scrollHeight - container.scrollTop <= container.clientHeight + 1 && (allDataWithoutAdding.length === 300 || allDataWithoutAdding.length > 300)) {
            // Show loading indicator
            setLazzyLoading(true);
            fetchData();
        }
    };

    

    return (
        <div style={{
            overflow: "scroll",
            padding: "20px 0px",
            flex: 1
        }} onScroll={handleScroll}>
            <Timeline
                sx={{
                    [`& .${timelineOppositeContentClasses.root}`]: {
                        flex: 0.2,
                    },
                }}
                
            >
                {
                    data.map((d, i) => {
                        return (
                            <TimelineItem key={i} ref={i === ind ? ref : null}>
                                <TimelineOppositeContent color="text.secondary">
                                    <Lefty data={d} ticketTime={ticketTime}/>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <ColorlibDot>
                                        {
                                            data[i].current_status === "closed" ? // to-do
                                                <Tooltip title={data[i].current_status} >
                                                    <DoneIcon sx={{
                                                        padding: "2px",
                                                        boxSizing: "border-box",
                                                        transition: "padding 0.3s ease"
                                                    }} />
                                                </Tooltip>
                                                :
                                                <Tooltip title={data[i].current_status} >
                                                    <MoreHorizIcon sx={{
                                                        padding: "2px",
                                                        boxSizing: "border-box",
                                                        transition: "padding 0.3s ease"
                                                    }} />
                                                </Tooltip>
                                        }
                                    </ColorlibDot>
                                    <ColorlibConnector />
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Righty data={d} />
                                </TimelineContent>
                            </TimelineItem>
                        );
                    })
                }
            </Timeline>
            {LazzyLoading &&
             <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <Box sx={{ width: '90%' }}>
                    <LinearProgress />
                </Box>
            </Box>
         }
        </div>
    );
}

function Lefty({ data, ticketTime }) {

    return (
        <div style={{
            height: "100px",
            width: "100%",
            display: "flex",
            flexDirection: "row-reverse"
        }}>
            {
                <Stack direction="column" spacing={1} style={{ float: "right" }}>
                    <Chip label={
                        ticketTime === "Delayed" ? "Delayed" : 
                        data.completed_time ? "Completed on" : "Ongoing"
                    } color={ticketTime === "Delayed" ? "error" : data.completed_time ? "success" : "warning"} variant="filled" style={{ borderRadius: "8px", fontWeight: "bold", fontSize: "10px" }} />

                    <Chip label={
                        data.completed_time ? formatDate(data.completed_time) : formatDate(data.created_time)
                    } color="primary" variant="outlined" style={{ borderRadius: "8px", fontWeight: "bold" }} />
                </Stack>
            }
        </div>
    );
}

function Righty({ data }) {
    const completed = data.completed_date ? true : false;

    return (
        <div style={{
            width: "100%",
            borderRadius: "10px",
            padding: "10px",
            boxSizing: "border-box",
            backgroundColor: "rgb(248 250 252)",
            boxShadow: "1px 1px 4px 0.5px rgba(71, 85, 105, 0.3)",
            marginBottom: "10px",
            display: "flex",
            flexDirection: "column",
            // gap: "5px",
            // borderRight: "3px solid " + (completed ? "green" : "crimson"),
            paddingLeft: "15px",
            boxShadow: "10px 0 15px -2px #A4060633 inset",
            borderLeft: "3px solid #A40606",
        }}>
            <div style={{ fontWeight: "bold" }}>{data.title}</div>
            <Ticket data={data} />
        </div>
    );
}

export function formatDate(date) {
    if(date === null || date === undefined) {
        return date;
    }
    date = new Date(date);
    const year = date.getFullYear();
    const month = date.toLocaleString('en-US', { month: 'short' }); // Get month name (e.g., "Apr")

    const day = String(date.getDate()).padStart(2, '0');

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format

    return `${day}-${month}-${year}, ${formattedHours}:${minutes} ${ampm}`;
}

function sortData(data) {
    data.sort((a, b) => {
        const date_a = a.completed_time ? new Date(a.completed_time) : new Date(a.created_time);
        const date_b = b.completed_time ? new Date(b.completed_time) : new Date(b.created_time);

        return date_a > date_b;
    });
}
//ignorei18n_end